import React, { useContext } from "react";

// Composants
import { ListeAllProjets } from "./Projets/ListeAllProjets";
import { ListeProjetsByUserId } from "./Projets/ListeProjetsByUserId";
import { ListeModeles } from "./Modeles/ListeModeles";
import { ListeTiersByUserIdAndTypeTiers } from "./Tiers/ListeTiersByUserIdAndTypeTiers";
import { ListeUtilisateurs } from "./Utilisateurs/ListeUtilisateurs";
import { GestionCorpsDeMétiers } from "./CorpsDeMétiers/GestionCorpsDeMétiers";
import { GestionActions } from "./Actions/GestionActions";
import { ListeAgences } from "./Tiers/ListeAgences";
import { ListeAllAgences } from "./Tiers/ListeAllAgences";
import { ListeRéponses } from "./Réponses/ListeRéponses";
import { ListeCatégories } from "./Catégories/ListeCatégories";
import { CreateNewTiers } from "./Tiers/CreateNewTiers";
import { CreateNewModele } from "./Modeles/CreateNewModele";
import { ListeAttentesAgences } from "./Attentes/ListeAttentesAgences";
import { ListeAttentesPartenaires } from "./Attentes/ListeAttentesPartenaires";
import { ListeFichiers } from "./Fichiers/ListeFichiers";
import { ListeAllTiers } from "./Tiers/ListeAllTiers";
import { DépôtExpress } from "./Fichiers/DépôtExpress";

import { AuthContext } from "../contexts/AuthProvider";

import "./css/Global.css";
import "./css/Zone2.css";

export function Zone2() {
  const {
    selectedMenu,
    debugAffichage,
    userId,
    isManager,
    isAgence,
    isPrestataire,
    handleSelectedArchive,
  } = useContext(AuthContext);
  console.log("Zone2 selectedMenu", selectedMenu);





  const renderContent = () => {
    console.log("Zone 2 renderContent", selectedMenu);
    switch (selectedMenu) {
      case "NEW CLIENT":
        return (
          <>
            {userId && (isAgence || isPrestataire) && (
              <CreateNewTiers typeTiers="CLIENT" />
            )}
          </>
        );

      case "NEW PARTENAIRE":
        return (
          <>
            {userId && (isAgence || isPrestataire) && (
              <CreateNewTiers typeTiers="PARTENAIRE" />
            )}
          </>
        );
      case "NEW PRESTATAIRE":
        return (
          <>
            {userId && isManager && <CreateNewTiers typeTiers="PRESTATAIRE" />}
          </>
        );
      case "NEW AGENCE":
        return (
          <>{userId && (isAgence || isPrestataire) && <CreateNewTiers typeTiers="AGENCE" />}</>
        );

      case "NEW MODÈLE":
        return (
          // <>{userId && isAdmin && <CreateNewTiers typeTiers="AGENCE" />}</>

          <>{userId  && <CreateNewModele/>}</>
        );
 

      case "ATTENTES":
        return (
          <div className="cadre-zone2">
            <ListeAttentesPartenaires AllAgences={false} />
          </div>
        );

      case "RÉPONSES":
          return (
            <div className="cadre-zone2">
              <ListeRéponses />
            </div>
          );
  
       
      
        case "DÉPÔT EXPRESS":
          return (
            <div className="cadre-zone2">
                <DépôtExpress />
            </div>
          );


      case "REQUÊTES":
        return <ListeAttentesAgences AllAgences={false} />;

      case "ALL DOCUMENTS":
        return (
          <>
            {/* On liste tous les fichiers */}
            <ListeFichiers AllAgences={true} />
          </>
        );

      case "ALL PROJETS":  
        // On récupère tous les projets l'Agence
        return <ListeAllProjets cibleProjets={"CLIENT"} />;


      case "ALL CLIENTS":
        return (
          <>
            <h5 className="titre-zone2">ALL CLIENTS</h5> 
             {/* Tous les Tiers CLIENTS De l'Agence */}
            <ListeAllTiers typeTiers="CLIENT" />
          </>
        );


        case "ALL PARTENAIRES":
          return (
            <>
              <h5 className="titre-zone2">ALL PARTENAIRES</h5> 
              <ListeAllTiers typeTiers="PARTENAIRE" />
            </>
          );


        case "ALL PRESTATAIRES":
          return (
            <>
              <h5 className="titre-zone2">ALL PRESTATAIRES</h5> 
              <ListeAllTiers typeTiers="PRESTATAIRE" />
            </>
          );
    

      case "ALL REQ":
        return <ListeAttentesAgences AllAgences={true} />;

      case "PROJETS CLIENTS":
        // On liste tous les projets en Cours de userId
        return <ListeProjetsByUserId cibleProjets={"CLIENT"}/>;


      case "PROJETS PARTENAIRES":
        // On liste tous les projets en Cours de userId
        return <ListeProjetsByUserId cibleProjets={"PARTENAIRE"}/>;

      case "UTILISATEURS":
        return <ListeUtilisateurs />;

      case "SYNTHÈSES":
        // Affichage Complet ou Léger
        // Complet c'est pour le DashBoard
        // Léger c'est pour les Synthèses
        return <ListeModeles AllAgences={false} Affichage={"Léger"} />;

      case "ALL DASHBOARD":
        // Affichage Complet ou Léger
        return <ListeModeles AllAgences={true} Affichage={"Léger"} />;

      case "PARTENAIRES":
        return (
          <>
            <h5 className="titre-zone2">PARTENAIRES</h5>
            <ListeTiersByUserIdAndTypeTiers
              typeTiers="PARTENAIRE"  
            />
          </>
        );
      case "CLIENTS":
        return (
          <>
            <h5 className="titre-zone2">CLIENTS</h5>
            <ListeTiersByUserIdAndTypeTiers
              typeTiers="CLIENT"
            />
          </>
        );



      case "ADMIN MÉTIERS":
        return <GestionCorpsDeMétiers />;

      case "ADMIN ACTIONS":
        return <GestionActions type="administration" />;

      case "ADMIN AGENCES":
        return <ListeAllAgences typeTiers="AGENCE" />;

      case "ADMIN CATÉGORIES":
        // return <ListeAttentesAgences AllAgences={true} />;

        return (
          <ListeCatégories
            AllAgences={true}
          />
        );


      case "ADMIN GÉNÉRAL":
        return <ListeAllAgences typeTiers="ADMINISTRATEUR" />;

      case "ALL AGENCES":
        return <ListeAgences />;

      case "ADMIN MODÈLES":
        // Affichage Complet ou Léger
        return (
          <ListeModeles
            AllAgences={true}
            Affichage={"Complet"}
            ModeGestion={"modeAdministration"}
          />
        );

      default:
        return null;
    }
  };

  return (
    // <div className="zone-2 marge-zones cadre-zone2">

    <div className="zone-2 marge-zone">
      {/* Juste pour savoir où sont les zones */}
      {debugAffichage && <h4 className="zone-title">Zone 2</h4>}
      {renderContent()}
    </div>
  );
}
