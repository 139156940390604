import React, { useEffect, useState, useContext } from "react";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import BoutonDownLoad from '../Actions/BoutonDownLoad'; // Assurez-vous que le chemin est correct
import {
  BsArchive,
  BsLightning,
  BsPeople,
  BsFileEarmarkText
} from "react-icons/bs";

import { Tooltip } from "react-tooltip";
import "react-datepicker/dist/react-datepicker.css";
import "../css/Global.css";
import io from 'socket.io-client';
import Cookies from "js-cookie";

export const DépôtExpress = React.memo(({ AllAgences }) => {

  const [actionsFichier, setActionsFichier] = useState([]);
  const [selectedRubrique, setSelectedRubrique] = useState("");
  const [selectedPrefixe, setSelectedPrefixe] = useState("");
  const {
    userId,
    userTiersId,
  } = useContext(AuthContext);

  const [typeTiers, setTypeTiers] = useState("");
  const { urlServeurNode } = useContext(ServerContext);

  useEffect(() => {
    if (!urlServeurNode) return;

    async function fetchTypeTiers() {
      try {
        const response = await customAxios.get(`${urlServeurNode}/api/tiers/${userTiersId}`);
        setTypeTiers(response.data.typeTiers);

        console.log("DépôtExpress fetchTypeTiers typeTiers", typeTiers);
      } catch (error) {
        console.error("Erreur lors de la récupération des données initiales", error);
      }
    }

    async function fetchActionsFichier() {
      try {
        const response = await customAxios.get(`${urlServeurNode}/api/actions/typesActions/${typeTiers}/FICHIER`);
        setActionsFichier(response.data.actionsFichier);

        console.log("DépôtExpress fetchActionsFichier actionsFichier", response.data.actionsFichier);
      } catch (error) {
        console.error("Erreur lors de la récupération des actions de type FICHIER", error);
      }
    }

    fetchTypeTiers();
    if (typeTiers) {
      fetchActionsFichier();
    }

  }, [urlServeurNode, userTiersId, typeTiers]);

  const uniqueRubriques = Array.from(new Set(actionsFichier.map(action => action.rubrique)));

  const handleRubriqueChange = (event) => {
    setSelectedRubrique(event.target.value);
    setSelectedPrefixe(""); // Reset prefixe when rubrique changes
  };

  const handlePrefixeChange = (event) => {
    setSelectedPrefixe(event.target.value);
  };

  const filteredPrefixes = actionsFichier
    .filter(action => action.rubrique === selectedRubrique)
    .map(action => action.prefixe);

  return (
    <div>
      <table style={{ width: '100%', tableLayout: 'fixed' }}>
        <thead className="table-header-requetes">
          <tr style={{ backgroundColor: 'var(--bg-zone)' }}>
            <th style={{ width: "20%", textAlign: "center" }}>
              <span style={{ fontSize: "1.2rem", transform: "scale(1.1)" }}>
                {/* <BsFileEarmarkText /> */}
                TYPE DE DOCUMENT
              </span>
            </th>
            <th style={{ width: "20%", textAlign: "center" }} className="sortable-header">
              <span style={{ fontSize: '1.3rem', transform: 'scale(1.1)' }}>
                <BsPeople />
              </span>
            </th>
            <th style={{ width: "21%", textAlign: "center" }} className="sortable-header">
              <span style={{ fontSize: '1.3rem', transform: 'scale(1.1)' }}>
                <BsLightning />
              </span>
            </th>
          </tr>
        </thead>
        <tbody style={{ display: "table", maxHeight: "800px", overflowY: "auto", width: "100%" }}>
          <tr>
            <td style={{ width: "20%", textAlign: "center" }}>
              <select onChange={handleRubriqueChange}>
                <option value="">Sélectionnez un type de document</option>
                {uniqueRubriques.map((rubrique, index) => (
                  <option key={index} value={rubrique}>
                    {rubrique}
                  </option>
                ))}
              </select>
            </td>
            <td style={{ width: "20%", textAlign: "center" }}>
                {selectedRubrique && filteredPrefixes.length > 1 && (
                  <select onChange={handlePrefixeChange}>
                    <option value="">Sélectionnez un préfixe</option>
                    {filteredPrefixes.map((prefixe, index) => (
                      <option key={index} value={prefixe}>
                        {prefixe}
                      </option>
                    ))}
                  </select>
                )}
              </td>
            <td style={{ width: "21%", textAlign: "center" }}>
              {/* Là on télécharge le fichier */}
              <BoutonDownLoad etape={{ rubrique: selectedRubrique, prefixe: selectedPrefixe }} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});