import React, { useContext, useCallback, useState } from "react";
import { CountProjetsByUserId } from "./Projets/CountProjetsByUserId";
import { CountModeles } from "./Modeles/CountModeles";
import { CountCatégories } from "./Catégories/CountCatégories";
import { customAxios } from "./Utilities/utilities";
import { CountAllProjetsClients } from "./Projets/CountAllProjetsClients";

import { CountListeAllAgences } from "./Tiers/CountListeAllAgences";
import { CountListeTiers } from "./Tiers/CountListeTiers";
import { CountActions } from "./Actions/CountActions";
import { CountEtapesAttentes } from "./Etapes/CountEtapesAttentes";
import { CountAttentesTiers } from "./Attentes/CountAttentesTiers";

import { CountProjetsClientsByUserId } from "./Projets/CountProjetsClientsByUserId";
import { CountCorpsDeMétiers } from "./CorpsDeMétiers/CountCorpsDeMétiers";
import { BsStack    } from "react-icons/bs";
import { FaUserCog , FaChevronRight} from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import "./css/Zone1.css";
import "./css/Global.css";
import { AuthContext } from "../contexts/AuthProvider";
import { Tooltip } from "react-tooltip";
import ServerContext from "../contexts/ServeurProvider";

function ListItem({ icon, text, countComponent, onClick, tooltipId, tooltipContent }) {
  return (
    <li 
      onClick={onClick} 
      className="ligne-espacée d-flex align-items-center souris-pointeur"
      data-tooltip-id={tooltipId}
      data-tooltip-content={tooltipContent}
      data-tooltip-place="top"
    >
      {icon}
      <span className="ms-2 me-1">{text}</span>
      {countComponent}
      <Tooltip id={tooltipId} place="top" effect="solid" className="custom-tooltip" />
    </li>
  );
}

// Affiche les menus de la sone1 en fonction du role de l'utilsateur connecté
// (isAgence, isPrestataire, isPartenaire, isManager, isAdmin) différents éléments de la liste sont affichés

export function Zone1() {
  const {
    userId,
    userRole,
    handleProjetId,
    handleModeleId,
    debugAffichage,
    isManager,
    isAgence,
    isAdmin,
    isPartenaire,
    isPrestataire,
    handleSelectedMenu,
    handleSelectedArchive,
    showZone1,
    isMenuToggled,
    handleIsMenuToggled 
  } = useContext(AuthContext);

  const { urlServeurNode } = useContext(ServerContext);

  const [showSubMenu, setShowSubMenu] = useState(false);

  // Là on récupère les projets de l'utilisateur en cours qui sont invalides
  //
  const checkInvalidProjects = useCallback(async () => {
    try {
      // Là on récupère tous les projets invalides

      console.log("Zone1 checkInvalidProjects de userid",userId );
      const invalidProjets = await customAxios.post(`${urlServeurNode}/api/projets/InvalidProjetByUserid`,  {userId});
      console.log("Zone1 checkInvalidProjects invalidProjets ",invalidProjets );
      if (invalidProjets.data.length > 0) {
        const invalidProjects = invalidProjets.data.map(project => 
          `Projet ${project.nomProjet} : ${project.invalidSteps.join(', ')}`
        ).join('\n');
        alert(`Les projets suivants ne sont pas valides :\n${invalidProjects}`);
      }
    } catch (error) {
      console.error("Erreur lors de la vérification des projets invalides:", error);
    }
  }, []);

  const createClickHandler = useCallback(
    (menu) => async () => {
      await checkInvalidProjects();
      handleSelectedMenu(menu);

      if (!showZone1) {
        handleIsMenuToggled(!isMenuToggled);
      }

      handleSelectedArchive(null);
      console.log("Zone1 createClickHandler handleProjetId(null)");
      handleProjetId(null); // réinitialiser le projetId ici
      handleModeleId(null);
    },
    [handleProjetId, handleModeleId, handleSelectedMenu, showZone1, isMenuToggled, handleIsMenuToggled, checkInvalidProjects]
  );


  console.log("Zone1 DEBUT isAgence", isAgence, "isPrestataire", isPrestataire);

  return (
    <div className="zone-1 marge-zones">
      {debugAffichage && <h4 className="zone-title">Zone 1 {userRole} </h4>}

      <ul className="liste-sans-puces">
        {(isAgence || isPrestataire) && (
          <>
            <ListItem
              icon={<BsStack className="couleur-icon double-size" />}
              text="REQUÊTES"
              countComponent={<CountEtapesAttentes AllAgences={false} />}
              onClick={createClickHandler("REQUÊTES")}
              tooltipId="tooltip-requetes"
              tooltipContent="Voir toutes les requêtes"
            />
              <ListItem
              icon={<BsStack className="couleur-icon double-size" />}
              text="PROJETS"
              // On récupère @userId les projets en cours de ce userId
              countComponent={<CountProjetsClientsByUserId etat={"enCours"} cibleProjets={"CLIENT"} />}
              onClick={() => setShowSubMenu(!showSubMenu)}
              tooltipId="tooltip-projets"
              tooltipContent="Voir tous les projets en cours"
            />
            {showSubMenu && (
                <>
                  <ListItem
           
                    icon={<FaChevronRight style={{ marginLeft: '12px' }} />}
                    text="CLIENTS"
                    countComponent={<CountProjetsClientsByUserId etat={"enCours"} cibleProjets={"CLIENT"} />}
                    onClick={createClickHandler("PROJETS CLIENTS")}
                    tooltipId="tooltip-projets-client"
                    tooltipContent="Voir tous les projets clients en cours"
                  />
                  <ListItem
                    icon={<FaChevronRight style={{ marginLeft: '12px' }} />}
                    text="PARTENAIRES"
                    countComponent={<CountProjetsClientsByUserId etat={"enCours"} cibleProjets={"PARTENAIRE"} />}
                    onClick={createClickHandler("PROJETS PARTENAIRES")}
                    tooltipId="tooltip-projets-partenaires"
                    tooltipContent="Voir tous les projets partenaires en cours"
                  />
                </>
              )}
            <ListItem
              icon={<BsStack className="couleur-icon double-size" />}
              text="CLIENTS"
              countComponent={<CountListeTiers typeTiers="CLIENT" AllAgences={false} />}
              onClick={createClickHandler("CLIENTS")}
              tooltipId="tooltip-clients"
              tooltipContent="Voir tous les clients"
            />
            <ListItem
              icon={<BsStack className="couleur-icon double-size" />}
              text="PARTENAIRES"
              countComponent={<CountListeTiers typeTiers="PARTENAIRE" AllAgences={false} />}
              onClick={createClickHandler("PARTENAIRES")}
              tooltipId="tooltip-partenaires"
              tooltipContent="Voir tous les partenaires"
            />
            {/* <ListItem
              icon={<BsStack className="couleur-icon double-size" />}
              text="ARCHIVES"
              countComponent={<CountProjetsByUserId etat={"enArchive"} />}
              onClick={createClickHandler("ARCHIVES")}
              tooltipId="tooltip-archives"
              tooltipContent="Voir les archives de projets"
            /> */}
            <ListItem
              icon={<BsStack className="couleur-icon double-size" />}
              text="SYNTHÈSES"
              onClick={createClickHandler("SYNTHÈSES")}
              tooltipId="tooltip-syntheses"
              tooltipContent="Voir les synthèses"
            />
            <hr />
          </>
        )}

        {isPartenaire && (
          <>
            <ListItem
              icon={<BsStack className="couleur-icon double-size" />}
              text="ATTENTES"
              // countComponent={<CountEtapesAttentesTiers  typeTiers={"PARTENAIRE"}/>}
              countComponent={<CountAttentesTiers  typeTiers={"PARTENAIRE"}/>}
              onClick={createClickHandler("ATTENTES")}
              tooltipId="tooltip-attentes"
              tooltipContent="Voir les attentes"
            />

            <ListItem
              icon={<BsStack className ="couleur-icon double-size" />}
              text="RÉPONSES"
              countComponent={<CountProjetsByUserId etat={"enArchive"} />}
              onClick={createClickHandler("RÉPONSES")}
              tooltipId="tooltip-réponses-partenaires"
              tooltipContent="Voir les éléments de réponse"
            />

          <ListItem
              icon={<BsStack className ="couleur-icon double-size" />}
              text="DÉPÔT EXPRESS"
              onClick={createClickHandler("DÉPÔT EXPRESS")}
              tooltipId="tooltip-réponses-partenaires"
              tooltipContent="Déposer un nouveau document"
            />
          </>
        )}

        {isManager && (
          <>
            <ListItem
              icon={<FaUserCog className="couleur-icon double-size" />}
              text="ALL REQ"
              countComponent={<CountEtapesAttentes AllAgences={true} />}
              onClick={createClickHandler("ALL REQ")}
              tooltipId="tooltip-all-req"
              tooltipContent="Voir toutes les requêtes"
            />
            <ListItem
              icon={<FaUserCog className="couleur-icon double-size" />}
              text="ALL DASHBOARD"
              onClick={createClickHandler("ALL DASHBOARD")}
              tooltipId="tooltip-all-dashboard"
              tooltipContent="Voir le tableau de bord"
            />

            <ListItem
              icon={<FaUserCog className="couleur-icon double-size" />}
              text="ALL PROJETS"
              countComponent={<CountAllProjetsClients />}
              onClick={createClickHandler("ALL PROJETS")}
              tooltipId="tooltip-all-projets"
              tooltipContent="Voir tous les projets en cours"
            />
            <ListItem
              icon={<FaUserCog className="couleur-icon double-size" />}
              text="ALL CLIENTS"
              countComponent={<CountListeTiers typeTiers="CLIENT" AllAgences={true} />}
              onClick={createClickHandler("ALL CLIENTS")}
              tooltipId="tooltip-all-projets"
              tooltipContent="Voir tous les clients en cours"
            />

            <ListItem
              icon={<FaUserCog className="couleur-icon double-size" />}
              text="ALL PARTENAIRES"
              countComponent={<CountListeTiers typeTiers="PARTENAIRE" AllAgences={true} />}
              onClick={createClickHandler("ALL PARTENAIRES")}
              tooltipId="tooltip-all-projets"
              tooltipContent="Voir tous les partenaires en cours"
            />

            <ListItem
              icon={<FaUserCog className="couleur-icon double-size" />}
              text="ALL DOCUMENTS"
              onClick={createClickHandler("ALL DOCUMENTS")}
              tooltipId="tooltip-all-documents"
              tooltipContent="Voir tous les documents"
            />



            {/* <ListItem
              icon={<FaUserCog className="couleur-icon double-size" />}
              text="ALL ARCHIVES"
              countComponent={<CountAllProjets etat={"enArchive"} />}
              onClick={createClickHandler("ALL ARCHIVES")}
              tooltipId="tooltip-all-archives"
              tooltipContent="Voir toutes les archives de projets"
            /> */}
            <ListItem
              icon={<FaUserCog className="couleur-icon double-size" />}
              text="ALL PRESTATAIRES"
              countComponent={<CountListeTiers typeTiers="PRESTATAIRE" AllAgences={true} />}
              onClick={createClickHandler("ALL PRESTATAIRES")}
              tooltipId="tooltip-all-prestataires"
              tooltipContent="Voir tous les prestataires"
            />

            <hr />
            <ListItem
              icon={<IoMdSettings className="couleur-icon" />}
              text="ADMIN MÉTIERS"
              countComponent={<CountCorpsDeMétiers />}
              onClick={createClickHandler("ADMIN MÉTIERS")}
              tooltipId="tooltip-admin-metiers"
              tooltipContent="Voir l'administration des métiers"
            />
            <ListItem
              icon={<IoMdSettings className="couleur-icon" />}
              text="ADMIN ACTIONS"
              countComponent={<CountActions />}
              onClick={createClickHandler("ADMIN ACTIONS")}
              tooltipId="tooltip-admin-actions"
              tooltipContent="Voir l'administration des actions"
            />
            <ListItem
              icon={<IoMdSettings className="couleur-icon" />}
              text="ADMIN MODÈLES"
              countComponent={<CountModeles />}
              onClick={createClickHandler("ADMIN MODÈLES")}
              tooltipId="tooltip-admin-modeles"
              tooltipContent="Voir l'administration des modèles"
            />
            <ListItem
              icon={<IoMdSettings className="couleur-icon" />}
              text="ADMIN AGENCES"
              countComponent={<CountListeAllAgences typeTiers="AGENCE" />}
              onClick={createClickHandler("ADMIN AGENCES")}
              tooltipId="tooltip-admin-agences"
              tooltipContent="Voir l'administration des agences"
            />
            <ListItem
              icon={<IoMdSettings className="couleur-icon" />}
              text="ADMIN CATÉGORIES"
              countComponent={<CountCatégories />}
              onClick={createClickHandler("ADMIN CATÉGORIES")}
              tooltipId="tooltip-admin-categories"
              tooltipContent="Voir l'administration des catégories"
            />
            <ListItem
              icon={<IoMdSettings className="couleur-icon" />}
              text="ADMIN GÉNÉRAL"
              countComponent={<CountListeAllAgences typeTiers="ADMINISTRATEUR" />}
              onClick={createClickHandler("ADMIN GÉNÉRAL")}
              tooltipId="tooltip-admin-general"
              tooltipContent="Voir l'administration générale"
            />
          </>
        )}

        {isAdmin && (
          <>
            <ListItem
              icon={<IoMdSettings className="couleur-icon" />}
              text="ADMIN MODÈLES"
              countComponent={<CountModeles />}
              onClick={createClickHandler("ADMIN MODÈLES")}
              tooltipId="tooltip-admin-modeles-admin"
              tooltipContent="Voir l'administration des modèles"
            />
            <ListItem
              icon={<IoMdSettings className="couleur-icon" />}
              text="ADMIN MÉTIERS"
              countComponent={<CountCorpsDeMétiers />}
              onClick={createClickHandler("ADMIN MÉTIERS")}
              tooltipId="tooltip-admin-metiers-admin"
              tooltipContent="Voir l'administration des métiers"
            />
            <ListItem
              icon={<IoMdSettings className="couleur-icon" />}
              text="ADMIN ACTIONS"
              countComponent={<CountActions />}
              onClick={createClickHandler("ADMIN ACTIONS")}
              tooltipId="tooltip-admin-actions-admin"
              tooltipContent="Voir l'administration des actions"
            />

            <ListItem
              icon={<IoMdSettings className="couleur-icon" />}
              text="ADMIN CATÉGORIES"
              countComponent={<CountCatégories />}
              onClick={createClickHandler("ADMIN CATÉGORIES")}
              tooltipId="tooltip-admin-categories"
              tooltipContent="Voir l'administration des catégories"
            />
            <ListItem
              icon={<IoMdSettings className="couleur-icon" />}
              text="ADMIN AGENCES"
              countComponent={<CountListeAllAgences typeTiers="AGENCE" />}
              onClick={createClickHandler("ADMIN AGENCES")}
              tooltipId="tooltip-admin-agences-admin"
              tooltipContent="Voir l'administration des agences"
            />
          </>
        )}
      </ul>
    </div>
  );
}
