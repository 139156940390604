import React, { useEffect, useCallback, useState, useContext } from "react";
import ServerContext from "../../contexts/ServeurProvider";
import { AuthContext } from "../../contexts/AuthProvider";
import { customAxios } from "../Utilities/utilities";
import { iconRendering } from "../Utilities/iconRendering"; //  Accès aux Picto en fonction du type daction
import Cookies from "js-cookie";

import {
  BsArchive,
  BsLightning,
  BsClock,
  BsPeople,
  BsListCheck,
  BsFileEarmarkPerson,
} from "react-icons/bs";

import useGetModeles from "../Utilities/GetModeles";
import ReactDatePicker from "react-datepicker";
import { Tooltip } from "react-tooltip";
import "react-datepicker/dist/react-datepicker.css";
import "../css/Global.css";
import io from "socket.io-client";

// Si AgenceAuComplet si true tous les éléments de l'agence
// Si false seulement le userId

// Dans un autre fichier de votre projet React
import {
  getFontColor,
  getBackgroundClass,
  formatDate,
  calculerDifferenceJours,
  getTooltipTypeActionContent,
  cleanString,
} from "../Utilities/attentesEtapes";

export const ListeAttentesAgences = React.memo(({ AllAgences }) => {
  const { fetchModeles } = useGetModeles();
  const [etapesEnAttente, setEtapesEnAttente] = useState([]);
  const {
    userId,
    modeleId,
    handleProjetId,
    projetId,
    handleSelectedMenu,
    handleEtapeId,
    agenceId,
    modificationDestinataires,
    handleToggleDestinataires,
    searchTerm,
  } = useContext(AuthContext);

  const { urlServeurNode } = useContext(ServerContext);
  const [sortConfig, setSortConfig] = useState({
    key: "nomProjet",
    direction: "ascending",
  });
  const [selectedDestinataire, setSelectedDestinataire] = useState(null);
  const [selectedEtapeId, setSelectedEtapeId] = useState(null);
  const [editingArchive, setEditingArchive] = useState(null);
  const [editingEcheance, setEditingEcheance] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const filteredEtapesEnAttente = etapesEnAttente.filter((etape) => {
    const matchesDescriptionEtape = etape.descriptionEtape
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const matchesNomProjet = etape.nomProjet
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const matchesDestinataires = etape.destinatairesEtape.some((destinataire) =>
      destinataire.nom.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Convertir la date d'échéance en string au format 'dd/mm/yyyy' pour la recherche
    let matchesEcheance = false;
    if (etape.echeanceEtape) {
      const date = new Date(etape.echeanceEtape);
      const dateString = date.toLocaleDateString("fr-FR"); // Assurez-vous que le format correspond à celui que les utilisateurs vont rechercher
      matchesEcheance = dateString.includes(searchTerm);
    }

    // Retourne true si l'une des conditions est vraie
    return (
      matchesDescriptionEtape ||
      matchesNomProjet ||
      matchesDestinataires ||
      matchesEcheance
    );
  });

  useEffect(() => {
    handleToggleDestinataires(true); // Active l'affichage des destinataires par défaut
    console.log("ListeAttentesAgences: handleToggleDestinataires true");
    // Ici, vous pouvez ajouter d'autres opérations à effectuer au montage du composant

    // Cette fonction de nettoyage sera appelée lors du démontage du composant, si nécessaire
    return () => {
      // Nettoyage ou réinitialisation de l'état
    };
  }, []); // Le tableau de dépendances vide signifie que cet effet ne s'exécutera qu'au montage

  // Si on clique sur la checkbox pour passer l'étape en archive
  const ArchiveEtapeEtat = async (etapeId, projetId) => {
    try {
      // Détermination du nouvel état
      setEditingArchive(etapeId);
      let nouvelEtat = "enArchive";

      console.log("ArchiveEtapeEtat etapeId", etapeId, "projetId", projetId);

      // Mise à jour dans la base de données
      const response = await customAxios.patch(
        `${urlServeurNode}/api/projets/projet/${projetId}/etapeId/${etapeId}`,
        { etat: nouvelEtat, echeanceEtape: null } // L'échéance de l'étape archibvée passe à nulle
      );

      if (response.status === 200) {
        // Mise à jour réussie, rafraîchir la liste des étapes

        const updatedEtapes = etapesEnAttente.map((etape) =>
          etape.etapeId === etapeId
            ? { ...etape, etapeEtat: nouvelEtat }
            : etape
        );

        setEtapesEnAttente(updatedEtapes);
        setEditingArchive(null);
        console.log("ArchiveEtapeEtat etapesEnAttente", updatedEtapes);
      }

      // Gérer d'autres actions après la mise à jour, si nécessaire
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'étape :", error);
      // Gérer l'erreur, par exemple, en affichant une notification à l'utilisateur
    }
  };



  // Fonction pour montrer le DatePicker
  const showDatePicker = (etapeId, currentEcheance) => {
    console.log(
      "ListeAttentesAgences: showDatePicker appelé avec etapeId:",
      etapeId,
      "et currentEcheance:",
      currentEcheance
    );

    setEditingEcheance(etapeId); // Définir l'édition pour l'étape actuelle dans les deux cas

    if (currentEcheance) {
      setSelectedDate(new Date(currentEcheance)); // Utiliser l'échéance actuelle si elle est définie
    } else {
      setSelectedDate(new Date()); // Utiliser la date actuelle si aucune échéance n'est définie
      console.log(
        "ListeAttentesAgences: showDatePicker selectedDate",
        selectedDate
      );
    }
  };

    // Fonction pour activer un projet
    const activateProject = async (projectId) => {
      try {
        const response = await customAxios.patch(
          `${urlServeurNode}/api/projets/activer/${projectId}`
        );
        if (response.status === 200) {
          console.log("ListeAttentesAgences Projet activé avec succès");
        }
      } catch (error) {
        console.error("Erreur lors de l'activation du projet:", error);
      }
    };
  
    // Fonction pour désactiver un projet
    const deactivateProject = async (projectId) => {
      try {
        const response = await customAxios.patch(
          `${urlServeurNode}/api/projets/desactiver/${projectId}`
        );
        if (response.status === 200) {
          console.log("ListeAttentesAgences Projet désactivé avec succès");
        }
      } catch (error) {
        console.error("Erreur lors de la désactivation du projet:", error);
      }
    };

  const upDateIsValidProject = useCallback(
    async (currentProjetId) => {
      if (!currentProjetId) return;
      try {
        const response = await customAxios.get(
          `${urlServeurNode}/api/projets/validate/${currentProjetId}`
        );
        const { isValid, nomProjet, invalidSteps } = response.data;

        console.log("ListeAttentesAgences upDateIsValidProject ", nomProjet, isValid, invalidSteps);
        if (!isValid) {
          deactivateProject(currentProjetId); // Désactiver le projet si non valide
          return false;
        } else {

          activateProject(currentProjetId); // Activer le projet si valide
          return true;
        }
      } catch (error) {
        console.error("Erreur lors de la validation du projet:", error);
        return false;
      }
    },
    [urlServeurNode]
  );

  // Fonction pour cacher le DatePicker et mettre à jour la date d'échéance
  const onDateChange = (nouvelleEcheance, etapeId, projetId) => {
    setSelectedDate(nouvelleEcheance);

    const updateEcheance = async () => {
      let nouvelEtat;
      try {
        if (nouvelleEcheance === null) {
          nouvelEtat = "enCours";
        } else {
          nouvelEtat = "enAttente";
        }

        console.log(
          "ListeAttentesAgences: onDateChange projetId etapeId",
          projetId,
          etapeId
        );
        console.log(
          "ListeAttentesAgences: onDateChange nouvelEtat, nouvelleEcheance",
          nouvelEtat,
          nouvelleEcheance
        );
        const response = await customAxios.patch(
          `${urlServeurNode}/api/projets/projet/${projetId}/etapeId/${etapeId}`,
          {
            etat: nouvelEtat,
            echeanceEtape: nouvelleEcheance, // Mettez à jour la date d'échéance avec la nouvelle date
          }
        );

        if (response.status === 200) {
          console.log(
            "Mise à jour réussie",
            response.data,
            "nouvelleEcheance",
            nouvelleEcheance
          );
          // Mettez à jour l'état avec les nouvelles données de l'étape
          setEtapesEnAttente((prevEtapes) =>
            prevEtapes.map((etape) =>
              etape.etapeId === etapeId
                ? { ...etape, echeanceEtape: nouvelleEcheance }
                : etape
            )
          );
          setSelectedDate(new Date(nouvelleEcheance));
          setEditingEcheance(null);
          await upDateIsValidProject(projetId); // Valider le projet après la mise à jour de la date
  
        }
      } catch (error) {
        console.error("Erreur lors de la mise à jour de l'échéance", error);
      }
    };

    updateEcheance();

    console.log(
      "ListeAttentesAgences: onDateChange editingEcheance",
      editingEcheance
    );
  };

  const handleDescriptionClick = async (
    event,
    etapeId,
    projetId,
    etapeIndex
  ) => {
    event.preventDefault();

    const etapeActive = selectedEtapeId === etapeId ? null : etapeId;

    // Mise à jour de l'état selectedEtapeId
    setSelectedEtapeId(etapeActive);
    handleProjetId(projetId);

    console.log(
      "ListeAttentesAgences: handleDescriptionClick etapeActive",
      etapeActive
    );
    handleEtapeId(etapeActive); // Mettez à jour l'ID de l'étape actuellement sélectionnée
  };


  // Fonction pour vérifier si une chaîne est en UTF-8
  function isUTF8(str) {
    const decoder = new TextDecoder('utf-8', { fatal: true });
    try {
      const encoded = new TextEncoder().encode(str);
      decoder.decode(encoded);
      return true;
    } catch (e) {
      return false;
    }
  }
  
  const fetchAllEtapesEnAttente = useCallback(async () => {
    try {
      console.log(
        "ListeAttentesAgences: fetchAllEtapesEnAttente:",
        AllAgences ? "CIBLE AGENCE" : "CIBLE USER"
      );
  
      let attentes;
  
      if (AllAgences) {
        const tousLesModelesDetailles = await fetchModeles(agenceId);
        console.log(
          "ListeAttentesAgences: fetchAllEtapesEnAttente 1", agenceId,
          tousLesModelesDetailles
        );
  
        const modeleIds = tousLesModelesDetailles.map((modele) => modele._id);
  
        if (modeleIds && modeleIds.length > 0) {
          const payload = { modeleIds: modeleIds };
          const url = `${urlServeurNode}/api/projets/attentesByModeleIds`;
          try {
            const response2 = await customAxios.post(url, payload);
  
            console.log(
              "ListeAttentesAgences: fetchAllEtapesEnAttente 4",
              response2.data
            );
  
            // Vérification de l'encodage UTF-8
            if (isUTF8(JSON.stringify(response2.data))) {
              attentes = response2.data;
              console.log(
                "ListeAttentesAgences: fetchAllEtapesEnAttente 5",
                attentes
              );
            } else {
              console.error("Les données reçues ne sont pas en UTF-8.");
            }
          } catch (error) {
            console.error("Erreur lors de la requête POST :", error);
          }
        } else {
          console.error("modeleIds est vide ou non défini.");
        }
      } else {
        const requestBody = { userId: userId };
        const response = await customAxios.post(
          `${urlServeurNode}/api/projets/attentesByUserId`,
          requestBody
        );
  
        // Vérification de l'encodage UTF-8
        if (isUTF8(JSON.stringify(response.data))) {
          attentes = response.data;
        } else {
          console.error("Les données reçues ne sont pas en UTF-8.");
        }
      }
  
      console.log(
        "ListeAttentesAgences: fetchAllEtapesEnAttente 6:",
        AllAgences ? "CIBLE AGENCE" : "CIBLE USER",
        JSON.stringify(attentes),
        attentes
      );
      setEtapesEnAttente(attentes || []);
    } catch (error) {
      console.error(
        "ListeAttentesAgences: Erreur lors de la récupération des étapes en attente",
        error
      );
    }
  }, [userId, AllAgences, agenceId]);

  useEffect(() => {
    if (!urlServeurNode) return;

    fetchAllEtapesEnAttente();

    console.log(
      "ListeAttentesAgences: useEffect modificationDestinataires",
      modificationDestinataires
    );
    setSelectedDestinataire(null);

    console.log(
      "ListeAttentesAgences: const socket = io(urlServeurNode)",
      urlServeurNode
    );

    const socket = io(urlServeurNode, {
      path: "/ws",
      auth: {
        token: Cookies.get("userTokenAcces"),
      },
    });

    socket.on("updateEtape", (data) => {
      console.log("ListeAttentesAgences: webSockets updateEtape", data);
      fetchAllEtapesEnAttente(); // Rafraîchit les données chaque fois que l'événement est reçu
    });

    // Nettoyage de la connexion à la fermeture du composant

  }, [
    userId,
    modeleId,
    AllAgences,
    agenceId,
    editingEcheance,
    editingArchive,
    fetchAllEtapesEnAttente,
  ]);

  const fetchEtapesByEtapeId = useCallback(async () => {
      if (!selectedEtapeId) {
        console.warn(
          "ListeAttentesAgences fetchEtapesByEtapeId pas d'étape sélectionnée."
        );
        handleEtapeId(null); // Aucun Etape n'est sélectionnée, on force à null pour ListeDestinataire
        return;
      }
      try {

        const requestBody = {
          projetId: projetId,
          etapeId: selectedEtapeId,
        };
        const response = await customAxios.post(
          `${urlServeurNode}/api/projets/attentesByEtapeId`,
          requestBody
        );

        const attenteEtape = response.data;
        
      
        console.log(
          "ListeAttentesAgences: useEffect fetchEtapesByEtapeId",
          attenteEtape
        );

        setEtapesEnAttente((prevEtapes) => {
          const indexToUpdate = prevEtapes.findIndex(
            (etape) => etape.etapeId === attenteEtape.etapeId
          );

          if (indexToUpdate === -1) {
            // Si l'étape n'est pas trouvée, vous pouvez choisir de ne rien faire ou de gérer ce cas.
            return prevEtapes;
          }

          // Mise à jour de l'étape spécifique dans le tableau
          const updatedEtapes = [...prevEtapes];
          updatedEtapes[indexToUpdate] = attenteEtape;

          return updatedEtapes;
        });
      } catch (error) {
        console.error(
          "ListeAttentesAgences fetchEtapesByEtapeId Erreur lors de la récupération des étapes en attente",
          error
        );
      }
    }, [selectedEtapeId, projetId, handleEtapeId]);


  useEffect(() => {
    fetchEtapesByEtapeId();
  }, [userId, selectedEtapeId, modificationDestinataires]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleLabelClick = (projetId) => {
    console.log("ListeAttentesAgences: handleLabelClick", projetId);
    handleProjetId(projetId);
    handleSelectedMenu("PROJETS CLIENTS"); // Pour simuler un reroutage
  };

  const sortedEtapes = React.useMemo(() => {
    let sortableItems = [...filteredEtapesEnAttente]; // Copie du tableau original pour éviter la mutation
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [filteredEtapesEnAttente, sortConfig]);

  const handleDestinataireClick = (event, destinataire, etape) => {
    event.stopPropagation(); // Pour empêcher d'autres événements de se déclencher

    // Modifier l'état du destinataire sur lequel l'utilisateur a cliqué
    const nouvelEtat =
      destinataire.etat === "aRépondu" ? "enAttente" : "aRépondu";

    const updatedDestinataires = etape.destinatairesEtape.map((d) =>
      d.nom === destinataire.nom ? { ...d, etat: nouvelEtat } : d
    );

    // Appel API pour mettre à jour le destinataire
    updateDestinataireEtape(
      etape.projetId,
      etape.etapeId,
      updatedDestinataires
    );
  };

  // Cette fonction envoie les destinataires mis à jour au serveur
  const updateDestinataireEtape = async (
    projetId,
    etapeId,
    updatedDestinataires
  ) => {
    console.log(
      "ListeAttentesAgences: updateDestinataireEtape",
      projetId,
      etapeId,
      updatedDestinataires
    );

    try {
      const response = await customAxios.patch(
        `${urlServeurNode}/api/projets/projet/${projetId}/etapeId/${etapeId}`,
        {
          destinatairesEtape: updatedDestinataires,
        }
      );
      if (response.status === 200) {
        // Mise à jour réussie, rafraîchir la liste des étapes
        setEtapesEnAttente((prevEtapes) =>
          prevEtapes.map((etape) =>
            etape.etapeId === etapeId
              ? { ...etape, destinatairesEtape: updatedDestinataires }
              : etape
          )
        );
   
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour des destinataires", error);
    }
  };

  const renderDestinataires = (destinataires, etape) => {
    // console.log(
    //   "ListeAttentesAgences: renderDestinataires",
    //   destinataires,
    //   etape
    // );
    const sortedDestinataires = (destinataires || []) // Vérification pour destinataires
      .filter((destinataire) => destinataire && destinataire.nom) // Vérification pour chaque destinataire
      .sort((a, b) => (a.nom || "").localeCompare(b.nom || ""));

    const retour = sortedDestinataires.map(
      (destinataire, destinataireIndex) => {
        const key = `${destinataire.nom || ""}-${destinataireIndex}-`;
        // console.log("renderDestinataires key", key)
        return (
          <React.Fragment key={key}>
            <span
              style={{
                // cursor: "pointer",
                cursor:
                  destinataire.etat === "aRépondu" ? "pointer" : "default", // Curseur en pointer seulement si l'état est "aRépondu"

                borderRadius: "var(--border-radius-destinataires)",
                marginTop: "2px",
                marginBottom: "2px",
              }}
              className={
                destinataire.etat === "enAttente"
                  ? "destinataire-enAttente"
                  : destinataire.etat === "aRépondu"
                  ? "destinataire-aRépondu"
                  : ""
              }
              onClick={(event) => {
                if (destinataire.etat === "aRépondu") {
                  handleDestinataireClick(event, destinataire, etape); // Exécuter onClick seulement si l'état est "aRépondu"
                }
              }}
              data-tooltip-id={`infoDestinataire-${cleanString(
                destinataire.nom
              )}`}
              data-tooltip-content={
                destinataire.etat === "enAttente"
                  ? "Document en Attente"
                  : destinataire.etat === "aRépondu"
                  ? "Document Reçu"
                  : ""
              }
            >
              {`${destinataire.nom || ""}`}
            </span>
            <Tooltip
              id={`infoDestinataire-${cleanString(destinataire.nom)}`}
              place="top"
              effect="solid"
              className="custom-tooltip"
            ></Tooltip>
          </React.Fragment>
        );
      }
    );

    // console.log("renderDestinataires retour", retour);
    return retour;
  };

  return (
    // <div className="cadre-zone2-requetes">
    <div style={{ overflowY: "auto", maxHeight: "800px" }}>
      <table style={{ width: "100%", tableLayout: "fixed" }}>
        <thead className="table-header-requetes">
          <tr style={{ backgroundColor: "var(--bg-zone)" }}>
            <th
              style={{
                width: "2%",
                textAlign: "center",
              }}
            >
              <span style={{ fontSize: "1.2rem", transform: "scale(1.1)" }}>
                <BsArchive />
              </span>
            </th>
            <th
              style={{
                width: "15%",
                textAlign: "center",
              }}
              className="sortable-header" // Pour avoir le pointeur de la souris
              onClick={() => requestSort("nomProjet")}
              data-tooltip-id={`enteteProjet`}
              data-tooltip-content={"Liste des Projets"}
            >
              {/* PROJET */}

              <span style={{ fontSize: "1.3rem", transform: "scale(1.1)" }}>
                <BsFileEarmarkPerson />
              </span>

              <Tooltip
                id="enteteProjet"
                place="top"
                effect="solid"
                className="custom-tooltip"
              ></Tooltip>
            </th>

            <th
              style={{ width: "30%", textAlign: "center" }}
              className="sortable-header" // Pour avoir le pointeur de la souris
              onClick={() => requestSort("descriptionEtape")}
              data-tooltip-id={`enteteDescription`}
              data-tooltip-content={"Description de l'étape"}
            >
              {/* DESCRIPTION */}

              <span style={{ fontSize: "1.5rem", transform: "scale(1.1)" }}>
                <BsListCheck />
              </span>

              <Tooltip
                id="enteteDescription"
                place="top"
                effect="solid"
                className="custom-tooltip"
              ></Tooltip>
            </th>

            <th
              style={{ width: "40%", textAlign: "center" }}
              className="sortable-header" // Pour avoir le pointeur de la souris
              onClick={() => requestSort("destinatairesEtape")}
              data-tooltip-id={`enteteDestinataires`}
              data-tooltip-content={"Liste des destinataires"}
            >
              {/* DESTINATAIRES */}

              <span style={{ fontSize: "1.3rem", transform: "scale(1.1)" }}>
                <BsPeople />
              </span>
              <Tooltip
                id="enteteDestinataires"
                place="top"
                effect="solid"
                className="custom-tooltip"
              ></Tooltip>
            </th>

            <th
              style={{
                width: "6%",
                textAlign: "center", // Centre le texte horizontalement
              }}
              className="sortable-header" // Pour avoir le pointeur de la souris
              onClick={() => requestSort("typeEtape")}
              data-tooltip-id={`enteteActions`}
              data-tooltip-content={"Type d'actions"}
            >
              <span style={{ fontSize: "1.3rem", transform: "scale(1.1)" }}>
                <BsLightning />
              </span>
              <Tooltip
                id="enteteActions"
                place="top"
                effect="solid"
                className="custom-tooltip"
              ></Tooltip>
            </th>

            <th
              style={{
                width: "8%", // Appliquer la condition ici
                textAlign: "center", // Centre le texte horizontalement
              }}
              className="sortable-header" // Pour avoir le pointeur de la souris
              onClick={() => requestSort("echeanceEtape")}
              data-tooltip-id={`enteteEcheance`}
              data-tooltip-content={"Échéance de la requête"}
            >
              <span style={{ fontSize: "1.3rem", transform: "scale(1.1)" }}>
                <BsClock />
              </span>

              <Tooltip
                id="enteteEcheance"
                place="top"
                effect="solid"
                className="custom-tooltip"
              ></Tooltip>
            </th>
          </tr>
        </thead>

        <tbody
          style={{
            display: "table",
            maxHeight: "800px",
            overflowY: "auto",
            width: "100%",
          }}
        >
          {sortedEtapes.map((etape, index) => {
            const joursRestants = etape.echeanceEtape
              ? calculerDifferenceJours(etape.echeanceEtape)
              : "N/A";
            const classeBackground = getBackgroundClass(joursRestants);
            const fontColor = getFontColor(classeBackground);
            const key = etape.etapeId;
            const rowClass = index % 2 === 0 ? "bg-custom-requetes" : "";

            return (
              <tr
                key={`${key}-${index}`}
                className={`
                ${rowClass} 
              ${
                selectedEtapeId === key && !AllAgences
                  ? "selected-row"
                  : "annuler-selected-row"
              } ${
                  selectedEtapeId !== key && !AllAgences
                    ? "annuler-selected-row"
                    : ""
                }`}
              >
                <td
                  style={{
                    width: "2%",
                    borderRadius: "6px 0 0 6px",
                    paddingLeft: "6px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`archiveTask-${etape.etapeId}`} // Assurez-vous que `etape.etapeId` est unique
                    name={`archiveTask-${etape.etapeId}`}
                    value=""
                    checked={etape.etat === "enArchive"}
                    onChange={() => {
                      ArchiveEtapeEtat(etape.etapeId, etape.projetId);
                    }}
                    data-tooltip-id={`infoArchive-${etape.etapeId}`}
                    data-tooltip-content={"Archive la tâche"}
                  />
                  <>
                    <Tooltip
                      id={`infoArchive-${etape.etapeId}`}
                      place="top"
                      effect="solid"
                      className="custom-tooltip"
                    ></Tooltip>
                  </>
                </td>

                <td
                  style={{
                    width: "15%",
                    paddingLeft: "6px",
                    cursor: "pointer",
                  }}
                >
                  <span
                    onClick={() => handleLabelClick(etape.projetId)}
                    data-tooltip-id={`infoProjet-${etape.etapeId}`}
                    data-tooltip-content={`Type de projet: ${etape.descriptionProjet}`}
                    data-tooltip-place="top"
                  >
                    {etape.nomProjet}
                  </span>
                  <Tooltip
                    id={`infoProjet-${etape.etapeId}`}
                    place="top"
                    effect="solid"
                    className="custom-tooltip"
                  ></Tooltip>
                </td>

                <td
                  style={{
                    Width: "30%",
                    paddingLeft: "6px",
                  }}
                  onClick={(event) =>
                    etape.destinatairesModifiablesEtape
                      ? handleDescriptionClick(
                          event,
                          etape.etapeId,
                          etape.projetId,
                          etape.ordreEtape
                        )
                      : undefined
                  }
                  className="selection-description-col"
                  data-tooltip-id={`infoDescription-${etape.etapeId}`}
                  data-tooltip-content={
                    etape.destinatairesModifiablesEtape
                      ? "Cliquer pour modifier les destinataires"
                      : "Sélection des destinataires impossible"
                  }
                >
                  {etape.descriptionEtape}
                  <>
                    <Tooltip
                      id={`infoDescription-${etape.etapeId}`}
                      place="bottom"
                      effect="solid"
                      className="custom-tooltip"
                    ></Tooltip>
                  </>
                </td>

                <td
                  style={{
                    width: "40%",
                    paddingLeft: "8px",
                  }}
                >
                  <span className="destination-col">
                    {renderDestinataires(etape.destinatairesEtape, etape)}
                  </span>
                </td>

                <td
                  style={{
                    width: "6%",
                    textAlign: "center",
                  }}
                >
                  <span
                    className="action-col"
                    {...(etape.echeanceEtape && {
                      "data-tooltip-id": `infoAction-${etape.etapeId}-${index}`,
                      "data-tooltip-content": getTooltipTypeActionContent(
                        etape.typeEtape
                      ),
                      "data-tooltip-place": "top",
                    })}
                  >
                    {etape.echeanceEtape && iconRendering(etape)}
                  </span>
                  <Tooltip
                    id={`infoAction-${etape.etapeId}-${index}`}
                    place="top"
                    effect="solid"
                    className="custom-tooltip"
                  ></Tooltip>
                </td>

                <td
                  className={`echeance-col ${classeBackground}`}
                  style={{
                    cursor: "pointer",
                    width: "8%", // Appliquer la condition ici
                    fontSize: "var(--fs-plus-petite)",
                    color: fontColor,
                    textAlign: "center",
                  }}
                  {...(etape.echeanceEtape && {
                    "data-tooltip-id": `infoEcheance-${etape.etapeId}`,
                    "data-tooltip-content": "Modifier l'échéance",
                    "data-tooltip-place": "top",
                  })}
                  onClick={() =>
                    showDatePicker(etape.etapeId, etape.echeanceEtape)
                  }
                >
                  {editingEcheance === etape.etapeId ? (
                    <>
                      <ReactDatePicker
                        key={etape.etapeId}
                        selected={selectedDate}
                        onChange={(date) =>
                          onDateChange(date, etape.etapeId, etape.projetId)
                        }
                        inline
                      />
                      <button
                        onClick={() =>
                          onDateChange(null, etape.etapeId, etape.projetId)
                        }
                      >
                        Réinitialiser la qate
                      </button>
                    </>
                  ) : (
                    etape.echeanceEtape && formatDate(etape.echeanceEtape)
                  )}

                  <Tooltip
                    id={`infoEcheance-${etape.etapeId}`}
                    place="top"
                    effect="solid"
                    className="custom-tooltip"
                  ></Tooltip>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
});
