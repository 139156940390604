import React, { useState, useEffect } from "react";
import axios from "axios";

export function Siret() {
  const [searchType, setSearchType] = useState("siret");
  const [identifier, setIdentifier] = useState("");
  const [companyInfo, setCompanyInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [nafLibelle, setNafLibelle] = useState("");

  const getToken = async () => {
    try {
      const response = await axios.post("http://localhost:3002/token");
      return response.data.access_token;
    } catch (error) {
      console.error("Siret: getToken Erreur lors de la récupération du token", error);
      return null;
    }
  };

  // Fonction pour générer le token et faire la requête
  const fetchCompanyInfo = async (identifier, type) => {
    setLoading(true);
    setError(null);
    try {
      // Récupérer le token
      const token = await getToken();
      if (!token) {
        setError("Erreur lors de la récupération du token.");
        return;
      }

      let siret = identifier;
      if (type === "siren") {
        const urlSiren = `https://api.insee.fr/entreprises/sirene/V3.11/siren/${identifier}`;
        const responseSiren = await axios.get(urlSiren, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const periodes = responseSiren.data.uniteLegale?.periodesUniteLegale;
        if (periodes && periodes.length > 0) {
          const nic = periodes[0].nicSiegeUniteLegale;
          siret = identifier + nic;
        }
      }

      const urlSiret = `https://api.insee.fr/entreprises/sirene/V3.11/siret/${siret}`;
      const responseSiret = await axios.get(urlSiret, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setCompanyInfo(responseSiret.data);
      console.log("All Siret", responseSiret.data);
      console.log("Siret", responseSiret.data.etablissement.siren);
      const codeNaf =
        responseSiret.data.etablissement?.uniteLegale
          ?.activitePrincipaleUniteLegale;
      if (codeNaf) {
        fetchNafLibelle(codeNaf, token);
      }
    } catch (err) {
      if (err.response) {
        handleError(err.response.status);
      } else {
        setError("Erreur lors de la récupération des informations de l'entreprise.");
      }
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour obtenir le libellé NAF
  const fetchNafLibelle = async (codeNaf, token) => {
    try {
      const response = await axios.get(
        `https://api.insee.fr/metadonnees/V1/codes/nafr2/sousClasse/${codeNaf}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNafLibelle(response.data.intitule);
    } catch (err) {
      console.error("Erreur lors de la récupération du libellé NAF:", err);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      (searchType === "siret" && (identifier.length !== 14 || isNaN(identifier))) ||
      (searchType === "siren" && (identifier.length !== 9 || isNaN(identifier)))
    ) {
      setError(`Le numéro ${searchType.toUpperCase()} doit contenir ${searchType === "siret" ? "14" : "9"} chiffres.`);
      return;
    }
    fetchCompanyInfo(identifier, searchType);
  };

  const handleError = (status) => {
    switch (status) {
      case 401:
        setError("Jeton d'accès manquant ou invalide.");
        break;
      case 403:
        setError("Droits insuffisants pour consulter les données.");
        break;
      case 404:
        setError("Entreprise non trouvée.");
        break;
      default:
        setError("Erreur inconnue.");
    }
  };

  return (
    <div>
      <h4>Recherche d'entreprise par {searchType.toUpperCase()}</h4>
      <form onSubmit={handleSubmit}>
        <label>
          Type de recherche:
          <select
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
          >
            <option value="siret">SIRET</option>
            <option value="siren">SIREN</option>
          </select>
        </label>
        <label>
          Numéro {searchType.toUpperCase()}:
          <input
            type="text"
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
            required
          />
        </label>
        <button type="submit">Rechercher</button>
      </form>
      {loading && <p>Chargement...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {companyInfo && (
        <div>
          <h5>Informations de l'entreprise</h5>
          <p><strong>SIRET:</strong> {companyInfo.etablissement.siret}</p>
          <p><strong>SIREN:</strong> {companyInfo.etablissement.siren}</p>
          <p><strong>Dénomination:</strong> {companyInfo.uniteLegale?.denominationUniteLegale || "N/A"}</p>
          <p><strong>Activité Principale:</strong> {companyInfo.uniteLegale?.activitePrincipaleUniteLegale || "N/A"}</p>
          <p><strong>Adresse:</strong> {companyInfo.etablissement?.adresseEtablissement?.numeroVoieEtablissement || "N/A"} {companyInfo.etablissement?.adresseEtablissement?.libelleVoieEtablissement || "N/A"}, {companyInfo.etablissement?.adresseEtablissement?.codePostalEtablissement || "N/A"} {companyInfo.etablissement?.adresseEtablissement?.libelleCommuneEtablissement || "N/A"}</p>
          <p><strong>Date de Création:</strong> {companyInfo.etablissement?.dateCreationEtablissement || "N/A"}</p>
          <p><strong>Libellé NAF:</strong> {nafLibelle || "N/A"}</p>
        </div>
      )}
    </div>
  );
}